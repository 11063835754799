<template>
    <h1>Migration</h1>

    <p>
        MattaPHP offre une possibilité de gérer votre base de données avec des migrations que vous allez créer.
        Cela va permettre de garder un suivi sur les actions que vous effectué sur votre bas de données mais aussi
        de pouvoir travailler en groupe. Les migrations sont faites via <a href="https://phinx.org/" target="_blank" class="link">PHINX</a> qui permet de réaliser des 
        migrations complètes et rapidement.
    </p>

    <h2>Initialisation</h2>

    <p>
        Pour initialiser le système de migration du framework il faudra dans un premier temps effectué deux commandes :
    </p>
    <div class="code"><code>composer phinx init</code></div>
    <p>
        Cette commande va créer un fichier <code>phinx.php</code> à la racine de votre projet. <br><br>Puis la deuxième
        commande :
    </p>
    <div class="code"><code>composer db</code></div>
    <p>
        Cette commande va créer 2 dossiers <code>db/migartions/</code> et <code>db/seeds/</code> qui vont recueillir
        vos fichiers de migrations et de fixtures.
    </p>
    <p>
        Une fois ces commandes réalisé, il va falloir configurer le fichier généré <code>phinx.php</code>. 
        Vous pouvez faire un copié collé et remplacer tout le fichier.
    </p>
    <BlocCodeComponents :code="phinx" el="phinx" />

    <p>
        Ici on configure la connexion de la base de données pour pouvoir effectuer nos migrations ou fixtures.
    </p>

    <h2>Création d'une migration</h2>

    <p style="color:red;">
        Attention si vous utilisez un environnement docker pour votre base de donnée, pensez bien a effectué les
        commandes depuis le terminal du container php pour ne pas avoir de soucis ultérieurement.
    </p>
    <p>
        Pour créer une migration cela se fait via une commande :
    </p>
    <div class="code"><code>composer phinx create [NOM_MIGRATION]</code></div>
    <p>
        Cette commande va créer un fichier de migration daté du timestamp de sa date de création. Le fichier
        va contenir toutes les informations nécessaires pour vous aider, de base le fichier se constitue d'une
        méthode <code>change()</code> où on pourra faire nos créations et les annuler simplements. Cependant il existe une deuxième
        possibilité de fonctionner avec les méthodes <code>up()</code> et <code>down()</code>, ces deux là sont généralement utilisé pour des 
        requêtes bien précise. <br><br>
        Voici le fichier généré :
    </p>
    <BlocCodeComponents :code="migr1" el="migr1" />

    <p>
        Dans notre migration nous pouvons ajouter divers code pour créer nos table, voici un exemple qui regroupe
        la plupart des cas basique.
    </p>
    <BlocCodeComponents :code="migr2" el="migr2" />

    <p>
        Ce code permet de créer 2 tables : une table user et une table note, notre user aura divers type de champs
        et nous finissons par <code>->create()</code> pour faire comprendre à phinx qu'on veut créer sinon 
        nous avons d'autres types de champs comme <code>->update()</code> pour modifier ou encore <code>->save()</code>
        pour sauvegarder.
        <br><br>
        Il faut retenir 2 commandes en suite :
    </p>
    <div class="code"><code>composer phinx migrate</code></div>
    <p>
        Pour exécuter les migrations.
    </p>
    <div class="code"><code>composer phinx rollback</code></div>
    <p>
        Permet de faire un retour en arrière des migrations effectué.
    </p>

    <p>
        Pour plus de d'informations sur l'utilisation de Phinx vous pouvez vous diriger vers la documentation
        officielle : <a href="https://book.cakephp.org/phinx/0/en/index.html" class="link" target="_blank">phinx.org</a>
    </p>

    <h2>Fixtures</h2>

    <p>
        Phinx donne aussi la possibilité de faire des fixtures, des données de test pour le développement 
        de votre application. Ce sont les seeds, dossier créé dans l'initialisation de phinx. Pour cela nous 
        allonts utiliser Faker PHP pour créer des données aléatoire.
        <br><br>
        Pour créer une fixtures il suffit de faire la commande :
    </p>
    <div class="code"><code>composer phinx seed:create [NOM_SEED]</code></div>
    <p>
        Cela va vous créer un fichier seeds dans <code>db/seeds/</code> qui contient une méthode run qui va nous
        permettre de créer nos fausses données <br><br>
        Voici un exemple de fixtures :
    </p>
    <BlocCodeComponents :code="seeds" el="seeds" />

    <p>
        Une fois nos données rentré, il va falloir lancer la méthode run pour pouvoir obtenir ces données
        dans votre base :
    </p>
    <div class="code"><code>composer phinx seed:run</code></div>
</template>

<script>
import BlocCodeComponents from '../Code/BlocCodeComponents.vue'

export default {
    name: 'MigrationComponents',
    components: {
        BlocCodeComponents
    },
    data() {
        return {
            phinx: `<?php

require __DIR__ . '/vendor/autoload.php';

use Dotenv\\Dotenv;

$dotenv = Dotenv::createUnsafeImmutable(__DIR__);
$dotenv->load();

var_dump(getenv('DB_HOST'));

return
[
    'paths' => [
        'migrations' => './db/migrations',
        'seeds' => './db/seeds'
    ],
    'environments' => [
        'default_migration_table' => 'phinxlog',
        'default_environment' => 'development',
        'development' => [
            'adapter' => 'mysql',
            'host' => getenv('DB_HOST'),
            'name' => getenv('DB_NAME'),
            'user' => getenv('DB_USER'),
            'pass' => getenv('DB_PASSWORD'),
            'port' => '3306',
            'charset' => 'utf8',
        ]
    ],
    'version_order' => 'creation'
];
`,
            migr1: `<?php
declare(strict_types=1);

use Phinx\\Migration\\AbstractMigration;

final class FirstMigration extends AbstractMigration
{
    /**
     * Change Method.
     *
     * Write your reversible migrations using this method.
     *
     * More information on writing migrations is available here:
     * https://book.cakephp.org/phinx/0/en/migrations.html#the-change-method
     *
     * Remember to call "create()" or "update()" and NOT "save()" when working
     * with the Table class.
     */
    public function change(): void
    {
        
    }
}
`,
            migr2: `<?php \npublic function change(): void
{

    $this->table('user')
        ->addColumn('name', 'string')
        ->addColumn('age', 'datetime')
        ->addColumn('biographie', 'text', ['limit' => MysqlAdapter::TEXT_LONG])
        ->create();

    $this->table('note')
        ->addColumn('note', 'interger')
        ->addColumn('réussi', 'boolean')
        ->addColumn('user_id', 'integer', [
            'null' => true
        ])
        ->addForeignKey('user_id', 'user', 'id', [
            'delete' => 'SET_NULL',
            'update' => 'NO_ACTION'
        ])
        ->create();
}`,
            seeds: `<?php

use Faker\\Factory;
use Phinx\\Seed\\AbstractSeed;

class UserNoteSeed extends AbstractSeed
{
    /**
     * Run Method.
     *
     * Write your database seeder using this method.
     *
     * More information on writing seeders is available here:
     * https://book.cakephp.org/phinx/0/en/seeding.html
     */
    public function run(): void
    {
        $faker = Factory::create();

        $users = [];

        for ($i = 0; $i < 10; ++$i) {
            $timestamp = $faker->unixTime('now');
            $users[] = [
                'name' => $faker->sentence(6),
                'age' => date('Y-m-d H:i:s', $timestamp),
                'biographie' => $faker->text(300)
            ];
        };

        $notes = [];

        for ($i = 0; $i < 10; ++$i) {
            $notes[] = [
                'note' => rand(0,20),
                'réussi' => $faker->boolean(),
                'user_id' => rand(1,10)
            ];
        }

        $this->insert('user', $users);
        $this->insert('note', $notes);
    }
}
`

        }
    }
}
</script>